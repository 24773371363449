import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from "../../../components/BlogPost";
export const meta = {
  published: false,
  publishedAt: "2019-02-28",
  title: "How We Handle 12 Million Monthly Background Jobs With Node.js",
  summary: ""
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <BlogPost meta={meta}>{children}</BlogPost>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`👋 I am Max, the technical co-founder of `}<a parentName="p" {...{
        "href": "https://spectrum.chat"
      }}>{`Spectrum`}</a>{`. Spectrum is an `}<a parentName="p" {...{
        "href": "https://github.com/withspectrum/spectrum"
      }}>{`open source`}</a>{` chat app for large online communities and was recently acquired by GitHub. We are a team of three with a predominantly frontend and design background and have worked on it for close to two years.`}</p>
    <p>{`Our Node.js servers handled over 12 million background jobs last month, varying from sending an email to processing notifications. Let's dive into how we handle that scale!`}</p>
    <h3>{`Why Do We Need Background Jobs?`}</h3>
    <p>{`Some user actions trigger complex processes, like sending notification emails and spam checking when users post new messages. We do not want our users to wait long for responses, so we run these tasks asynchronously in the background.`}</p>
    <h3>{`What Does The Architecture Look Like?`}</h3>
    <p>{`We set up six worker servers that are solely responsible for background processing, and split our ~70 queues between them based on type. For example, one worker ("hermes") sends emails and another ("athena") handles notifications. Each worker is scaled to many instances that share the load.`}</p>
    <p>{`Our web servers add jobs to the queues and then respond to the users without waiting for the result. Our workers process them asynchronously in the background as they have time.`}</p>
    <h3>{`Where Are The Job Queues Stored?`}</h3>
    <p>{`To handle that we chose a package called `}<a parentName="p" {...{
        "href": "https://github.com/optimalbits/bull"
      }}>{`bull`}</a>{`, which uses Redis under the hood. We initially selected it over the alternatives due to its intuitive API, but it has also proven to be the most stable part of our entire infrastructure.`}</p>
    <p>{`Here is a real-world example of a job from our codebase:`}</p>
    <pre {...{
      "className": "language-javascript"
    }}><code parentName="pre" {...{
        "className": "language-javascript"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// API server`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Queue`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"bull"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` messageNotificationsQueue `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Queue`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"message-notifications"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

messageNotificationsQueue`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`add`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  messageId`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` message`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`id`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <pre {...{
      "className": "language-javascript"
    }}><code parentName="pre" {...{
        "className": "language-javascript"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// Worker server`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Queue`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"bull"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` messageNotificationsQueue `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Queue`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"message-notifications"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

messageNotificationsQueue`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`process`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`job`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// ...send message notifications for job.data.messageId...`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <h3>{`Would We Use Bull Again?`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      